import create from "zustand";
import shallow from "zustand/shallow";
import { setCookie } from "utils/common.utils";
import { tokenName } from "constants/constants";
import { AuthStore, User } from "stores/auth/model/auth-store.interface";

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  setUser: (user) => set({ user }),
  resetUser: () => {
    setCookie(tokenName, "", {
      "max-age": -1,
    });
    set({ user: null });
  },
  setProfileImage: (image) => {
    set((state) => ({
      ...state,
      user: { ...(state.user as User), profileImage: image },
    }));
  },
  loading: true,
  setLoading: (loading: boolean) => set({ loading: loading }),
}));

export const useExistUserStore = () => {
  const { user } = useAuthStore(
    (state) => ({ user: state.user as User }),
    shallow,
  );

  return user;
};
