import { getTokenFromCookies } from "utils/common.utils";
import { url } from "constants/constants";

export class HttpService {
  private readonly requestUrl: string;

  constructor(private controller: string) {
    this.requestUrl = `${url}${this.controller}`;
  }

  get(endpoint: string | null, signal?: AbortSignal) {
    return fetch(`${this.requestUrl}${endpoint ?? ""}`, {
      signal,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenFromCookies()}`,
        Culture: "ru",
      },
    });
  }

  post<T>(endpoint: string | null, body?: T, signal?: AbortSignal) {
    return fetch(`${this.requestUrl}${endpoint ?? ""}`, {
      signal,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenFromCookies()}`,
        Culture: "ru",
      },
      body: JSON.stringify(body ?? {}),
    });
  }

  delete<T>(endpoint: string | null, body?: T) {
    return fetch(`${this.requestUrl}${endpoint ?? ""}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Culture: localStorage.getItem("i18nextLng") as string,
        Authorization: `Bearer ${getTokenFromCookies()}`,
      },
      body: JSON.stringify(body ?? {}),
    });
  }
}
