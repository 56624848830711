import { AppTopBarPrimary } from "../AppTopBarPrimary/AppTopBarPrimary";
import { MainContent } from "../MainContent/MainContent";
import { SideMenu } from "../SideMenu/SideMenu";

export const MainView = () => {
  return (
    <>
      <AppTopBarPrimary />
      <MainContent />
      <SideMenu />
    </>
  );
};
