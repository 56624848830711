import { Redirect, Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { Services } from "../Services/Services";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "56px",
  },
}));

export const MainContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Switch>
        <Route path={"/services"} component={Services} />
        <Redirect to={"/services"} />
      </Switch>
    </div>
  );
};
