export const incorrectDataWasSentToTheServer =
  "incorrect data was sent to the server";
export const authorizationTokenIsInvalid = "authorization token is invalid";
export const passwordIsIncorrect = "password is incorrect";
export const userDoesNotExist = "user does not exist";
export const requestNotFound = "request not found";
export const anErrorOccurredOnTheServer = "an error occurred on the server";
export const anErrorOccurredWhileExecutingTheRequest =
  "an error occurred while executing the request";
export const invalidCredentials = "invalid credentials";
