import {
  forwardRef,
  cloneElement,
  HTMLAttributes,
  ReactElement,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  ComponentType,
} from "react";
import { List } from "react-virtualized";
import { Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ILookup } from "models/common.interface";
import { makeStyles } from "@material-ui/core/styles";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles(() => ({
  tag: {
    maxWidth: "calc(100% - 70px)",
  },
}));

const ListboxComponent: ComponentType<HTMLAttributes<HTMLElement>> = forwardRef(
  function ListboxComponent(props, ref) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 46;
    const listWidth = window.innerWidth - 32;
    const listHeight = itemCount < 5 ? itemCount * itemSize : 240;

    return (
      <div ref={ref as any}>
        <div {...other}>
          <List
            height={listHeight}
            width={listWidth}
            rowHeight={itemSize}
            overscanCount={10}
            rowCount={itemCount}
            rowRenderer={(props) => {
              return cloneElement(
                (children as { [key: number]: ReactElement })[props.index],
                {
                  style: props.style,
                },
              );
            }}
            role={role}
          />
        </div>
      </div>
    );
  },
);

export const VirtualizedAutocomplete = ({
  options,
  setOptions,
}: {
  options: ILookup[];
  setOptions: Dispatch<SetStateAction<string[]>>;
}) => {
  const classes = useStyles();

  const handleBuildingsChange = (event: ChangeEvent<{}>, value: ILookup[]) => {
    const newOptionsIds = value.map((item) => item.id);
    setOptions(newOptionsIds);
  };

  return (
    <Autocomplete
      classes={{
        tag: classes.tag,
      }}
      multiple
      limitTags={1}
      disableListWrap={true}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={handleBuildingsChange}
      ListboxComponent={ListboxComponent}
      disableCloseOnSelect
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Здание" />
      )}
      noOptionsText={"Нет элементов для отображения"}
    />
  );
};
