import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "6px",
    border: `1px solid ${theme.palette.grey[300]}`,
    overflow: "hidden",
  },
  error: {
    border: `1px solid ${red[500]}`,
  },
}));

interface IInputWrapProps {
  error?: boolean;
}

export const InputWrap: FC<IInputWrapProps> = ({ error = false, children }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${error ? classes.error : ""}`}>
      {children}
    </div>
  );
};
