import { FC } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PointerRightFilled from "../../../../assets/icons/PointerRightFilled";

const useStyles = makeStyles((theme) => ({
  buttonLabel: {
    display: "grid",
    gridTemplateColumns: "28px 1fr 24px",
    gap: "16px",
    textAlign: "left",
    textTransform: "none",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "5px",
    height: "64px",
  },
  itemTitle: {
    color: theme.palette.grey[900],
  },
  itemDesc: {
    fontSize: "12px",
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  iconWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "64px",
  },
  iconLarge: {
    fontSize: "28px",
  },
  iconSmall: {
    fontSize: "24px",
  },
}));

interface UserSettingsButtonListItemProps {
  IconComponent: FC<{ className: string }>;
  title: string;
  description?: string;
  onClick?: () => void;
}

export const UserSettingsButtonListItem = ({
  IconComponent,
  title,
  description,
  onClick,
}: UserSettingsButtonListItemProps) => {
  const classes = useStyles();

  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    } else {
      console.log("123");
    }
  };

  return (
    <Button
      fullWidth
      classes={{
        label: classes.buttonLabel,
      }}
      onClick={handleButtonClick}
    >
      <div className={classes.iconWrap}>
        <IconComponent className={classes.iconLarge} />
      </div>
      <div>
        <div className={classes.listItem}>
          <span className={classes.itemDesc}>{title}</span>
          <span className={classes.itemTitle}>{description ?? "-"}</span>
        </div>
      </div>
      <div>
        <PointerRightFilled className={classes.iconSmall} />
      </div>
    </Button>
  );
};
