import { FC, useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Preloader } from "../../Preloader/Preloader";

import { useDialogProfileStyles } from "components/Dialog/DialogProfilePhoto/dialogProfile.style";
import { getImageFromBytes } from "utils/common.utils";

interface DialogProfilePhotoProps {
  initialPhoto: string | null;
  onClose: () => void;
  onConfirm: (photo: string) => Promise<void>;
}

export const DialogProfilePhoto: FC<DialogProfilePhotoProps> = ({
  initialPhoto,
  onClose,
  onConfirm,
}) => {
  const classes = useDialogProfileStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogContentVisible, setIsDialogContentVisible] =
    useState<boolean>(true);

  const ref = useRef<AvatarEditor | null>(null);

  const handleConfirm = async () => {
    const current = ref.current;

    if (current === null) return;

    setIsLoading(true);
    await onConfirm(current.getImageScaledToCanvas().toDataURL());
    setIsLoading(false);
  };

  const handleClose = () => {
    setIsDialogContentVisible(false);
    onClose();
  };

  useEffect(() => {
    if (initialPhoto) {
      setIsDialogContentVisible(true);
    }
  }, [initialPhoto]);

  return isLoading ? (
    <Preloader />
  ) : (
    <Dialog
      classes={{ paper: classes.paper, paperFullWidth: classes.paperFullWidth }}
      fullWidth
      open={!!initialPhoto}
      onClose={handleClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        Выберите размер изображения
      </DialogTitle>
      <DialogContent className={classes.content}>
        {isDialogContentVisible && (
          <AvatarEditor
            ref={ref}
            image={getImageFromBytes(initialPhoto as string)}
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: "#000000",
            }}
            borderRadius={200}
            color={[255, 255, 255, 0.6]}
            scale={1}
            rotate={0}
          />
        )}
      </DialogContent>

      <DialogActions className={classes.actionsRoot}>
        <div className={classes.dialogActions}>
          <Button
            fullWidth
            variant={"contained"}
            disableElevation
            className={`${classes.button} ${classes.buttonClose}`}
            onClick={handleClose}
          >
            Закрыть
          </Button>
          <Button
            fullWidth
            variant={"contained"}
            disableElevation
            onClick={handleConfirm}
            className={`${classes.button} ${classes.buttonSend}`}
          >
            Применить
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
