import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { AppWrap } from "App";
import "./i18n/i18n.config";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";

import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

ReactDOM.render(
  <ErrorBoundary>
    <AppWrap />
  </ErrorBoundary>,
  document.getElementById("root"),
);

serviceWorkerRegistration.register();
