export enum MeterStatus {
  new,
  sent,
  accepted,
  notSent,
}

export enum MeterType {
  electricity,
  heating,
  waterCold,
  waterHot,
  gas,
}

export enum ServiceRequestEnum {
  Another,
  Electrician,
  Plumbing,
  Cleaning,
}
