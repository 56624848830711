import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MenuIcon from "@material-ui/icons/Menu";

import { UserData } from "components/AppTopBarPrimary/components/UserData";

import { useMenuStore } from "stores/menu/menu.store";

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[50],
    "&:before": {
      content: '" "',
      position: "absolute",
      top: "0",
      left: "0",
      height: "56px",
      width: "100%",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
    },
  },
  toolBar: {
    display: "flex",
    // justifyContent: "space-between",
  },
  iconButtonMenu: {
    marginLeft: "-12px",
  },
}));

export const AppTopBarPrimary = () => {
  const classes = useStyles();
  const { setIsOpen } = useMenuStore();

  return (
    <AppBar position={"fixed"} className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolBar}>
        <IconButton className={classes.iconButtonMenu} onClick={setIsOpen}>
          <MenuIcon />
        </IconButton>
        <UserData />
      </Toolbar>
    </AppBar>
  );
};
