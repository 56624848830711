import create from "zustand";

interface MenuStore {
  isOpen: boolean;
  setIsOpen: () => void;
}

export const useMenuStore = create<MenuStore>((set) => ({
  isOpen: false,
  setIsOpen: () => set((state) => ({ isOpen: !state.isOpen })),
}));
