import { HttpService } from "services/HttpService";
import { MultipleResponse } from "models/http-request.interface";
import { handleErrors } from "utils/request.utils";
import { IServicesRequestListItem } from "models/services/servicesRequests.interface";

const BuildingsService = new HttpService("/service-request");

export const getServicesRequestItems = (
  buildingIds: Array<string>,
  isArchive?: boolean,
  signal?: AbortSignal,
): Promise<MultipleResponse<IServicesRequestListItem>> => {
  return BuildingsService.post(
    `/get-mobile-requests${isArchive ? "?isArchive=true" : ""}`,
    { buildingIds: [...buildingIds], filter: {} },
    signal,
  )
    .then(handleErrors)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};
