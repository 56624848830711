import { CSSProperties, FC } from "react";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import { materialIcons } from "utils/icons";

import { IServicesRequestListItem } from "models/services/servicesRequests.interface";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    backgroundColor: theme.palette.grey[100],
  },
  cardContent: {
    padding: "24px 10px 10px",
  },
  requestHeader: {
    display: "flex",
    gap: 10,
  },
  requestAddress: {
    fontSize: 18,
    fontWeight: 500,
  },
  requestDetails: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
    fontSize: 12,
  },
  lineClamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 5,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    marginBottom: 0,
    fontSize: 14,
  },
  button: {
    fontWeight: 400,
    textTransform: "none",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 0,
  },
}));

export const ServicesNewCard = ({
  serviceItem,
}: {
  serviceItem: IServicesRequestListItem;
}) => {
  const classes = useStyles();

  const IconComponent: FC<{ style: CSSProperties }> =
    materialIcons[serviceItem.icon];

  return (
    <Card
      classes={{
        root: classes.cardRoot,
      }}
      elevation={0}
    >
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <div className={classes.requestHeader}>
          <IconComponent
            style={{ color: `${serviceItem.color}`, fontSize: 32 }}
          />
          <div style={{ flexGrow: 1 }}>
            <div className={classes.requestAddress}>
              {serviceItem.buildingAddress}
            </div>
            <div className={classes.requestDetails}>
              <div>
                <span style={{ marginRight: 10 }}>
                  {moment(serviceItem.createdDate).format("HH:mm")}
                </span>
                <span>
                  {moment(serviceItem.createdDate).format("DD.MM.yyyy")}
                </span>
              </div>
              <span>{serviceItem.clientName}</span>
            </div>
          </div>
        </div>
        <div>
          <p className={classes.lineClamp}>Lorem ips</p>
        </div>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button className={classes.button} variant={"outlined"}>
          Подробнее
        </Button>
      </CardActions>
    </Card>
  );
};
