import React from "react";

import DiningIcon from "@mui/icons-material/Dining";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WashIcon from "@mui/icons-material/Wash";
import TungstenIcon from "@mui/icons-material/Tungsten";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import TheatersIcon from "@mui/icons-material/Theaters";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SpaIcon from "@mui/icons-material/Spa";
import SpokeIcon from "@mui/icons-material/Spoke";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import RoofingIcon from "@mui/icons-material/Roofing";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PestControlIcon from "@mui/icons-material/PestControl";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LightIcon from "@mui/icons-material/Light";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import HouseIcon from "@mui/icons-material/House";
import HomeIcon from "@mui/icons-material/Home";
import HardwareIcon from "@mui/icons-material/Hardware";
import HandymanIcon from "@mui/icons-material/Handyman";
import GrassIcon from "@mui/icons-material/Grass";
import GiteIcon from "@mui/icons-material/Gite";
import EngineeringIcon from "@mui/icons-material/Engineering";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CottageIcon from "@mui/icons-material/Cottage";
import BuildIcon from "@mui/icons-material/Build";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";

export enum MaterialIcons {
  DiningIcon,
  FormatPaintIcon,
  LocalShippingIcon,
  WashIcon,
  TungstenIcon,
  TheaterComedyIcon,
  TheatersIcon,
  SupportAgentIcon,
  SpaIcon,
  SpokeIcon,
  SoupKitchenIcon,
  ShoppingCartIcon,
  RoomServiceIcon,
  RoomPreferencesIcon,
  RoofingIcon,
  RestaurantIcon,
  PestControlIcon,
  LibraryBooksIcon,
  LightIcon,
  LightbulbIcon,
  HouseIcon,
  HomeIcon,
  HardwareIcon,
  HandymanIcon,
  GrassIcon,
  GiteIcon,
  EngineeringIcon,
  EmojiObjectsIcon,
  DeliveryDiningIcon,
  CottageIcon,
  BuildIcon,
  BuildCircleIcon,
  AccessibleForwardIcon,
}

export const materialIcons: { [K in MaterialIcons]: React.FC<any> } = {
  [MaterialIcons.DiningIcon]: DiningIcon,
  [MaterialIcons.FormatPaintIcon]: FormatPaintIcon,
  [MaterialIcons.LocalShippingIcon]: LocalShippingIcon,
  [MaterialIcons.WashIcon]: WashIcon,
  [MaterialIcons.TungstenIcon]: TungstenIcon,
  [MaterialIcons.TheaterComedyIcon]: TheaterComedyIcon,
  [MaterialIcons.TheatersIcon]: TheatersIcon,
  [MaterialIcons.SupportAgentIcon]: SupportAgentIcon,
  [MaterialIcons.SpaIcon]: SpaIcon,
  [MaterialIcons.SpokeIcon]: SpokeIcon,
  [MaterialIcons.SoupKitchenIcon]: SoupKitchenIcon,
  [MaterialIcons.ShoppingCartIcon]: ShoppingCartIcon,
  [MaterialIcons.RoomServiceIcon]: RoomServiceIcon,
  [MaterialIcons.RoomPreferencesIcon]: RoomPreferencesIcon,
  [MaterialIcons.RoofingIcon]: RoofingIcon,
  [MaterialIcons.RestaurantIcon]: RestaurantIcon,
  [MaterialIcons.PestControlIcon]: PestControlIcon,
  [MaterialIcons.LibraryBooksIcon]: LibraryBooksIcon,
  [MaterialIcons.LightIcon]: LightIcon,
  [MaterialIcons.LightbulbIcon]: LightbulbIcon,
  [MaterialIcons.HouseIcon]: HouseIcon,
  [MaterialIcons.HomeIcon]: HomeIcon,
  [MaterialIcons.HardwareIcon]: HardwareIcon,
  [MaterialIcons.HandymanIcon]: HandymanIcon,
  [MaterialIcons.GrassIcon]: GrassIcon,
  [MaterialIcons.GiteIcon]: GiteIcon,
  [MaterialIcons.EngineeringIcon]: EngineeringIcon,
  [MaterialIcons.EmojiObjectsIcon]: EmojiObjectsIcon,
  [MaterialIcons.DeliveryDiningIcon]: DeliveryDiningIcon,
  [MaterialIcons.CottageIcon]: CottageIcon,
  [MaterialIcons.BuildIcon]: BuildIcon,
  [MaterialIcons.BuildCircleIcon]: BuildCircleIcon,
  [MaterialIcons.AccessibleForwardIcon]: AccessibleForwardIcon,
};
