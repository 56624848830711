import { HttpService } from "services/HttpService";
import { handleErrors } from "utils/request.utils";
import { FileUpload } from "models/common.interface";

const UserProfileService = new HttpService("/user-profile");

export const saveProfileImage = (image: FileUpload) => {
  return UserProfileService.post("/save-profile-picture", image)
    .then(handleErrors)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};
