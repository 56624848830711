import {
  getTokenFromCookies,
  handleCaughtError,
  setCookie,
} from "./common.utils";
import { getAuthToken, validateAuthToken } from "services/auth/auth.service";
import { useAuthStore } from "stores/auth/auth.store";
import { tokenName } from "constants/constants";
import { AuthCredentials } from "models/auth/auth.interface";

export const isTokenValid = async () => {
  try {
    if (!getTokenFromCookies()) {
      useAuthStore.getState().setLoading(false);
      return;
    }

    const tokenResponse = await validateAuthToken();
    // const newNotificationResponse = await newNotifications()
    useAuthStore.getState().setUser({
      ...tokenResponse.result,
      profileImage: tokenResponse.result.image?.content,
    });
  } catch (error) {
    handleCaughtError(error);
  } finally {
    useAuthStore.getState().setLoading(false);
  }
};

export const signIn = async (credentials: AuthCredentials) => {
  try {
    const { result } = await getAuthToken(credentials);
    setCookie(tokenName, result.token);
    await isTokenValid();
  } catch (error) {
    handleCaughtError(error);
  }
};
