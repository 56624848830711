import { Route, Switch } from "react-router-dom";

import { UserProfileTopBar } from "components/UserProfile/UserProfileTopBar/UserProfileTopBar";
import { UserProfileSwipeable } from "components/UserProfile/UserProfileSwipeable/UserProfileSwipeable";
import { NotificationsSettingsPage } from "../NotificationsSettings/NotificationsSettings";

export const UserProfile = () => {
  return (
    <Switch>
      <Route
        exact
        path={"/user-profile/notifications-settings"}
        component={NotificationsSettingsPage}
      />
      <Route path={"/user-profile"}>
        <UserProfileTopBar />
        <UserProfileSwipeable />
      </Route>
    </Switch>
  );
};
