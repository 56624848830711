import { useEffect, useState, ChangeEvent, useMemo, useRef } from "react";

import { Section } from "components/Section/Section";
import { TabPreloader } from "components/Preloader/TabPreloader";

import { getBuildingsLookup } from "services/building/building.service";

import { ILookup } from "models/common.interface";
import { handleCaughtError } from "utils/common.utils";
import {
  IServicesRequestList,
  IServicesRequestListItem,
} from "models/services/servicesRequests.interface";
import { getServicesRequestItems } from "services/services/services.service";
import { VirtualizedAutocomplete } from "components/VirtualizedAutocomplete/VirtualizedAutocomplete";
import { ServicesNewCard } from "components/Services/ServicesCard/ServicesNewCard";

export const ServicesMainTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [servicesRequestsLoading, setServicesRequestsLoading] = useState(false);
  const [buildings, setBuildings] = useState<ILookup[]>([]);
  const [buildingIds, setBuildingIds] = useState<string[]>([]);
  const [servicesRequestsItems, setServicesRequestsItems] =
    useState<IServicesRequestList>({ newRequests: [], activeRequests: [] });

  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    getAdditionalData();
  }, []);

  useEffect(() => {
    if (buildingIds.length > 0) {
      setServicesRequestsLoading(true);
    }
    getServicesRequests();
  }, [buildingIds]);

  const getAdditionalData = async () => {
    try {
      const buildings = await getBuildingsLookup();
      setBuildings(buildings.result.value);
    } catch (error) {
      handleCaughtError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getServicesRequests = async () => {
    try {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const response = await getServicesRequestItems(
        buildingIds,
        false,
        abortControllerRef.current.signal,
      );
      const resultResponse: IServicesRequestList =
        response.result.values.reduce(
          (acc, currValue, index, arr) => {
            currValue.isNewRequest
              ? acc.newRequests.push(currValue)
              : acc.activeRequests.push(currValue);
            return acc;
          },
          { newRequests: [], activeRequests: [] } as {
            newRequests: IServicesRequestListItem[];
            activeRequests: IServicesRequestListItem[];
          },
        );
      setServicesRequestsItems(resultResponse);
    } catch (error) {
      handleCaughtError(error);
    } finally {
      setServicesRequestsLoading(false);
    }
  };

  return isLoading ? (
    <TabPreloader />
  ) : (
    <>
      <VirtualizedAutocomplete
        options={buildings}
        setOptions={setBuildingIds}
      />
      {servicesRequestsLoading ? (
        <TabPreloader />
      ) : (
        <>
          <Section title={"Новые"}>
            {servicesRequestsItems.newRequests.map((item) => {
              return <ServicesNewCard key={item.id} serviceItem={item} />;
            })}
          </Section>
          <Section title={"Активные"}>
            {servicesRequestsItems.activeRequests.map((item) => {
              return (
                <div>
                  {item.serviceName} {`${item.isNewRequest}`}
                </div>
              );
            })}
          </Section>
        </>
      )}
    </>
  );
};
