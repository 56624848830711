import React, { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { toast } from "material-react-toastify";

import { AppBar, Toolbar, IconButton, Avatar } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { DialogProfilePhoto } from "components/Dialog/DialogProfilePhoto/DialogProfilePhoto";

import CloseFilled from "assets/icons/CloseFilled";
import AccountFilled from "assets/icons/AccountFilled";
import EditFilled from "assets/icons/EditFilled";
import LogOutFilled from "assets/icons/LogOutFilled";
import { useAuthStore, useExistUserStore } from "stores/auth/auth.store";
import {
  getFileBytesAndExtension,
  getImageFromBytes,
  handleCaughtError,
} from "utils/common.utils";
import { saveProfileImage } from "services/user-profile/user-profile.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[50],
      "&:before": {
        content: '" "',
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
      },
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      height: "150px",
      padding: "16px",
    },
    iconButton: {
      padding: 0,
    },
    iconPhotoWrap: {
      position: "relative",
      height: "78px",
    },
    iconPhoto: {
      fontSize: "78px",
      color: theme.palette.grey[500],
    },
    editButtonIcon: {
      position: "absolute",
      right: "3px",
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
      backgroundColor: theme.palette.grey[700],
      boxShadow: `0 0 0 2px #fff`,
      "&:hover": {
        backgroundColor: theme.palette.grey[700],
      },
    },
    editIcon: {
      fontSize: "12px",
      color: theme.palette.background.default,
    },
    centerBlock: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "18px",
    },
    userName: {
      fontSize: "16px",
      fontWeight: 500,
    },
  }),
);

interface InitialPhoto {
  name: string;
  bytes: string;
}

export const UserProfileTopBar = () => {
  const user = useExistUserStore();
  const { setProfileImage } = useAuthStore();
  const classes = useStyles();
  const history = useHistory();

  const [initialImage, setInitialImage] = useState<InitialPhoto | null>(null);

  const goBack = () => {
    history.goBack();
  };

  const logOut = () => {
    useAuthStore.getState().resetUser();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();

      const base64 = reader.result as string;
      image.src = base64;
      image.onload = () => {
        setInitialImage({
          bytes: getFileBytesAndExtension(base64).bytes,
          name: file.name,
        });
      };
    };

    e.target.value = "";
  };

  const handlePhotoSave = async (base64Photo: string) => {
    try {
      const { bytes, extension } = getFileBytesAndExtension(base64Photo);

      const image = {
        name: uuid(),
        extension,
        content: bytes,
        creationDate: new Date().toISOString(),
      };
      setProfileImage(bytes);
      setInitialImage(null);
      await saveProfileImage(image);
      toast.success("Изображение сохранено");
    } catch (error) {
      handleCaughtError(error);
    }
  };

  return (
    <AppBar position={"fixed"} className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolBar}>
        <IconButton onClick={goBack} className={classes.iconButton}>
          <CloseFilled />
        </IconButton>
        <div className={classes.centerBlock}>
          <div className={classes.iconPhotoWrap}>
            {user.profileImage ? (
              <Avatar
                style={{ width: 65, height: 65 }}
                className={classes.iconPhoto}
                alt={"Profile image"}
                src={getImageFromBytes(user.profileImage)}
              />
            ) : (
              <AccountFilled className={classes.iconPhoto} />
            )}
            <IconButton component={"label"} className={classes.editButtonIcon}>
              <input
                accept={"image/*"}
                hidden
                type={"file"}
                onChange={handleInputChange}
              />
              <EditFilled className={classes.editIcon} />
            </IconButton>
          </div>
          <span className={classes.userName}>
            {user?.lastName} {user.firstName}
          </span>
        </div>
        <IconButton onClick={logOut} className={classes.iconButton}>
          <LogOutFilled />
        </IconButton>
      </Toolbar>
      <DialogProfilePhoto
        onConfirm={handlePhotoSave}
        onClose={() => setInitialImage(null)}
        initialPhoto={initialImage?.bytes ?? ""}
      />
    </AppBar>
  );
};
