import { toast } from "material-react-toastify";

import { tokenName } from "constants/constants";
import { SingleResponse } from "models/http-request.interface";

export const getTokenFromCookies = (): string | null => {
  const cookie = document.cookie
    .split(" ")
    .map((item) => (item.split("=")[0] === tokenName ? item : null))
    .filter((item) => !!item);
  if (cookie[0]?.includes(";")) {
    return cookie[0]?.replace(";", "").slice(tokenName.length + 1);
  }
  return cookie[0] ? cookie[0].slice(tokenName.length + 1) : null;
};

export const setCookie = (name: string, value: string, options?: any): void => {
  options = {
    path: "/",
    samesite: "strict",
    ...options,
  };

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value,
  )}`;

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const capitalizeFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const getFileBytesAndExtension = (base64: string) => {
  const [meta, bytes] = base64.split(",");
  const extension = meta.split(":")[1].split(";")[0];

  return {
    bytes,
    extension,
  };
};

export const getImageFromBytes = (bytes: string) => {
  return `data:image/jpeg;base64,${bytes}`;
};

export const handleCaughtError = (error: Error | SingleResponse<string>) => {
  if (error instanceof Error) {
    if (error.name === "AbortError") return;
    throw new Error();
  }

  return toast.error(error.result.value);
};
