import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const PointerRightFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.1599 6L8.74988 7.41L13.3299 12L8.74988 16.59L10.1599 18L16.1599 12L10.1599 6Z" />
    </SvgIcon>
  );
};

export default PointerRightFilled;
