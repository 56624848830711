import { Drawer, List, ListItem, ListSubheader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useMenuStore } from "stores/menu/menu.store";

const useStyles = makeStyles(() => ({
  list: {
    width: "70vw",
  },
}));

export const SideMenu = () => {
  const classes = useStyles();
  const { isOpen, setIsOpen } = useMenuStore();

  return (
    <Drawer open={isOpen} onClose={setIsOpen} anchor={"left"}>
      <List
        className={classes.list}
        onClick={setIsOpen}
        subheader={<ListSubheader>Menu</ListSubheader>}
      >
        {["First Item", "Second Item", "Third Item", "Fourth Item"].map(
          (item) => {
            return (
              <ListItem button divider key={item}>
                {item}
              </ListItem>
            );
          },
        )}
      </List>
    </Drawer>
  );
};
