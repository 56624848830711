import { ChangeEvent } from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) => ({
  user: {},
  appBarRoot: {
    position: "sticky",
    top: "56px",
    flexDirection: "row",
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[50],
    "&:before": {
      content: '" "',
      position: "absolute",
      top: "0",
      left: "0",
      height: "48px",
      width: "100%",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
    },
    "&$user": {
      top: "150px",
    },
  },

  tabsRoot: {
    width: "100%",
    "& .indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        width: "82%",
        backgroundColor: theme.palette.grey[900],
      },
    },
  },
  tabRoot: {
    width: "50%",
    maxWidth: "unset",
    textTransform: "none",
  },
  textColorInherit: {
    opacity: "unset",
  },
  tabSelected: {
    color: theme.palette.grey[900],
  },
}));

interface TabItem {
  name: string;
  showNotificationIcon?: boolean;
}

const renderLabel = (item: TabItem) => {
  return item.showNotificationIcon ? (
    <span style={{ display: "flex" }}>
      <span
        style={{
          alignSelf: "center",
          marginRight: "8px",
          height: "8px",
          width: "8px",
          backgroundColor: green[500],
          borderRadius: "50%",
        }}
      />
      <span>{item.name}</span>
    </span>
  ) : (
    <span>{item.name}</span>
  );
};

interface AppBarWithTabsProps {
  index: number;
  handleChange: (e: ChangeEvent<{}>, index: number) => void;
  tabs: TabItem[];
  user?: boolean;
}

export const AppBarWithTabs = ({
  index,
  handleChange,
  tabs,
  user = false,
}: AppBarWithTabsProps) => {
  const classes = useStyles();

  return (
    <AppBar
      className={`${classes.appBarRoot} ${user ? classes.user : ""}`}
      elevation={0}
    >
      <Tabs
        classes={{
          root: classes.tabsRoot,
          indicator: "indicator",
        }}
        value={index}
        onChange={handleChange}
        TabIndicatorProps={{ children: <span /> }}
      >
        {tabs.map((item, i) => {
          return (
            <Tab
              key={i}
              classes={{
                root: classes.tabRoot,
                textColorInherit: classes.textColorInherit,
                selected: classes.tabSelected,
              }}
              label={renderLabel(item)}
            />
          );
        })}
      </Tabs>
    </AppBar>
  );
};
