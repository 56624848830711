import { FC } from "react";
import { CardContent, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  cardRoot: {
    width: "100%",
    maxHeight: "unset",
    maxWidth: "unset",
    margin: "8px 0",
    paddingBottom: "8px",
    boxShadow: "none",
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  innerContent: {
    padding: "0",
    height: "100%",
  },
  headerNodeRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    fontSize: "14px",
    fontWeight: 500,
  },
  contentRoot: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

interface SectionProps {
  title: string;
}

export const Section: FC<SectionProps> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Card className={classes.cardRoot}>
      <div className={classes.innerContent}>
        <div className={classes.headerNodeRoot}>
          <span>{title}</span>
        </div>
        <CardContent className={classes.contentRoot}>{children}</CardContent>
      </div>
    </Card>
  );
};
