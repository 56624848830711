import { useState } from "react";

import MailIncomeOutlined from "assets/icons/MailIncomeOutlined";
import MailOutlined from "assets/icons/MailOutlined";

import { AppTopBarSecondary } from "components/AppTopBarSecondary/AppTopBarSecondary";
import { Section } from "components/Section/Section";
import { UserNotificationsSettingsListItem } from "../UserProfile/UserProfileTabs/components/UserNotificationsSettingsListItem";

interface NotificationsSettings {
  emailSubscribing: boolean;
  newRequests: boolean;
}

export const NotificationsSettingsPage = () => {
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationsSettings>({
      emailSubscribing: false,
      newRequests: false,
    });

  const handleSwitchChange = (
    field: keyof NotificationsSettings,
    value: boolean,
  ) => {
    const settingsCopy = { ...notificationSettings };

    settingsCopy[field] = value;
    setNotificationSettings(settingsCopy);
  };

  return (
    <div style={{ paddingTop: "56px" }}>
      <AppTopBarSecondary title={"Уведомление и рассылка"} />
      <Section title={"Рассылка"}>
        <UserNotificationsSettingsListItem
          IconComponent={MailOutlined}
          title={"Рассылка на email"}
          description={"Отправка счетов, квитанций"}
          onSwitch={(value) => handleSwitchChange("emailSubscribing", value)}
        />
      </Section>
      <Section title={"Push-уведомления"}>
        <UserNotificationsSettingsListItem
          IconComponent={MailIncomeOutlined}
          title={"Новые заявки"}
          description={"Уведомление о новых заявках"}
          onSwitch={(value) => handleSwitchChange("newRequests", value)}
        />
      </Section>
    </div>
  );
};
