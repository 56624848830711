import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const LogOutFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M14 6V21H3V19H5V3H14V4H19V19H21V21H17V6H14ZM10 11V13H12V11H10Z" />
    </SvgIcon>
  );
};

export default LogOutFilled;
