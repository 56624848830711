import { ReactNode, Component } from "react";
import ErrorInfo from "components/ErrorBoundary/ErrorInfo";
import { ErrorResponse } from "models/common.interface";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: ErrorType;
}

export type ErrorType = Error | ErrorResponse | null;

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: ErrorType) {
    return { hasError: true, error: error };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorInfo error={this.state.error as NonNullable<ErrorType>} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
