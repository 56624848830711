import { makeStyles } from "@material-ui/core/styles";

export const useInputStyles = makeStyles(() => ({
  root: {
    fontWeight: 500,
    borderRadius: 0,
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
