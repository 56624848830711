import { ChangeEvent, FC } from "react";
import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  listItemWrap: {
    display: "grid",
    gridTemplateColumns: "28px 1fr 24px",
    gap: "16px",
    width: "100%",
  },
  listItemContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "5px",
    height: "64px",
  },
  itemTitle: {
    color: theme.palette.grey[900],
  },
  itemDesc: {
    fontSize: "12px",
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  iconWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "64px",
  },
  iconLarge: {
    fontSize: "28px",
  },
  iconSmall: {
    fontSize: "24px",
  },
}));

interface UserNotificationsSettingsListItemProps {
  IconComponent: FC<{ className: string }>;
  title: string;
  description: string;
  onSwitch: (value: boolean) => void;
}

export const UserNotificationsSettingsListItem = ({
  IconComponent,
  title,
  description,
  onSwitch,
}: UserNotificationsSettingsListItemProps) => {
  const classes = useStyles();

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSwitch(e.target.checked);
  };

  return (
    <div className={classes.listItemWrap}>
      <div className={classes.iconWrap}>
        <IconComponent className={classes.iconLarge} />
      </div>
      <div>
        <div className={classes.listItemContent}>
          <span className={classes.itemDesc}>{title}</span>
          <span className={classes.itemTitle}>{description}</span>
        </div>
      </div>
      <div>
        <Switch onChange={handleSwitchChange} color={"primary"} />
      </div>
    </div>
  );
};
