import { useHistory } from "react-router-dom";

import PasswordOutlined from "assets/icons/PasswordOutlined";
import SendOutlined from "assets/icons/SendOutlined";
import LightThemeOutlined from "assets/icons/LightThemeOutlined";
import LanguageFilled from "assets/icons/LanguageFilled";
import OpenNewOutlined from "assets/icons/OpenNewOutlined";
import { Section } from "components/Section/Section";
import { UserSettingsButtonListItem } from "components/UserProfile/UserProfileTabs/components/UserSettingsButtonListItem";

export const UserSettingsTab = () => {
  const history = useHistory();

  const goToNotificationsSettings = () => {
    history.push("/user-profile/notifications-settings");
  };

  return (
    <>
      <Section title={"Основные"}>
        <UserSettingsButtonListItem
          IconComponent={PasswordOutlined}
          title={"Пароль"}
          description={"Пароль входа в приложение"}
        />
        <UserSettingsButtonListItem
          IconComponent={SendOutlined}
          title={"Рассылка"}
          description={"Параметры рассылки"}
          onClick={goToNotificationsSettings}
        />
      </Section>
      <Section title={"Оформление"}>
        <UserSettingsButtonListItem
          IconComponent={LightThemeOutlined}
          title={"Внешний вид"}
          description={"Светлая тема"}
        />
        <UserSettingsButtonListItem
          IconComponent={LanguageFilled}
          title={"Язык"}
          description={"Русский"}
        />
      </Section>
      <Section title={"Прочее"}>
        <UserSettingsButtonListItem
          IconComponent={OpenNewOutlined}
          title={"О компании"}
          description={'ООО "Жилой микрорайон"'}
        />
      </Section>
    </>
  );
};
