import i18n from "i18next";

import { useAuthStore } from "stores/auth/auth.store";
import { capitalizeFirstLetter } from "utils//common.utils";
import {
  anErrorOccurredOnTheServer,
  anErrorOccurredWhileExecutingTheRequest,
  authorizationTokenIsInvalid,
  incorrectDataWasSentToTheServer,
  requestNotFound,
} from "i18n/constants/errors.translation";

export const handleErrors = async (response: Response) => {
  if (response.ok) return response.json();

  switch (response.status) {
    case 400:
      return Promise.reject({
        isSuccess: false,
        result: {
          status: response.status,
          value: capitalizeFirstLetter(i18n.t(incorrectDataWasSentToTheServer)),
        },
      });
    case 401:
      useAuthStore.getState().resetUser();

      return Promise.reject({
        isSuccess: false,
        result: {
          status: response.status,
          value: capitalizeFirstLetter(i18n.t(authorizationTokenIsInvalid)),
        },
      });
    case 404:
      return Promise.reject({
        isSuccess: false,
        result: {
          status: response.status,
          value: capitalizeFirstLetter(i18n.t(requestNotFound)),
        },
      });
    case 500:
      return Promise.reject({
        isSuccess: false,
        result: {
          status: response.status,
          value: capitalizeFirstLetter(i18n.t(anErrorOccurredOnTheServer)),
        },
      });
    default:
      return Promise.reject({
        isSuccess: false,
        result: {
          status: response.status,
          value: capitalizeFirstLetter(
            i18n.t(anErrorOccurredWhileExecutingTheRequest),
          ),
        },
      });
  }
};
