import { makeStyles } from "@material-ui/core/styles";

import SignalCellularConnectedNoInternet0BarIcon from "@material-ui/icons/SignalCellularConnectedNoInternet0Bar";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
  icon: {
    fontSize: "40px",
  },
}));

export const OfflinePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SignalCellularConnectedNoInternet0BarIcon className={classes.icon} />
      <p>Отсутствует подключение к интернету</p>
    </div>
  );
};
