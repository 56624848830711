import i18n from "i18next";
import ResourcesRU from "i18n/resources/resources-ru.json";
import { initReactI18next } from "react-i18next";

const resources = {
  ru: ResourcesRU,
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") ?? "ru",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
