import { useHistory } from "react-router-dom";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ArrowBackFilled from "assets/icons/ArrowBackFilled";

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[50],
    "&:before": {
      content: '" "',
      position: "absolute",
      top: "0",
      left: "0",
      height: "56px",
      width: "100%",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px",
  },
  title: {
    width: "100%",
    fontSize: "16px",
    fontWeight: 500,
  },
  iconButton: {
    paddingLeft: "8px",
  },
}));

interface MobileTopBarWithButtonsProps {
  title: string;
}

export const AppTopBarSecondary = ({ title }: MobileTopBarWithButtonsProps) => {
  const history = useHistory();
  const classes = useStyles();

  const goBack = () => {
    history.goBack();
  };

  return (
    <AppBar position={"fixed"} className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolBar}>
        <IconButton onClick={goBack} className={classes.iconButton}>
          <ArrowBackFilled />
        </IconButton>
        <div className={classes.title}>{title}</div>
      </Toolbar>
    </AppBar>
  );
};
