import { FC } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Portal from "components/Portal/Portal";

interface BackdropPreloaderProps {
  isLoading?: boolean;
}

export const Preloader: FC<BackdropPreloaderProps> = ({ isLoading = true }) => {
  return isLoading ? (
    <Portal>
      <Backdrop open style={{ zIndex: 5000 }}>
        <CircularProgress />
      </Backdrop>
    </Portal>
  ) : null;
};
