import { HttpService } from "services/HttpService";
import { handleErrors } from "utils/request.utils";
import { SingleResponse } from "models/http-request.interface";
import { ILookup } from "models/common.interface";

const BuildingsService = new HttpService("/building");

export const getBuildingsLookup = (): Promise<SingleResponse<ILookup[]>> => {
  return BuildingsService.get("/lookup")
    .then(handleErrors)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};
