import { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { CssBaseline } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";

//custom components
import { Layout } from "components/Layout/Layout";
import { AuthorizationPage } from "components/AuthorizationPage/AuthorizationPage";
import { OfflinePage } from "components/OfflinePage/OfflinePage";
import { Preloader } from "components/Preloader/Preloader";

//auth
import { useAuthStore } from "stores/auth/auth.store";

//theme
import { lightTheme, darkTheme } from "theme/theme";
import { isTokenValid } from "utils/auth.utils";

//toast notifications
import "material-react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "material-react-toastify";

//moment
import "moment/locale/ru";
import moment from "moment";
moment.locale("ru");

const useStyles = makeStyles(() => ({
  toastContainer: {
    top: "15px",
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: "90vw",
  },
  toast: {
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  toastBody: {
    textAlign: "center",
    lineHeight: "1.5",
  },
}));

const App = () => {
  const { user, loading } = useAuthStore();

  useEffect(() => {
    (async () => {
      if (!localStorage.getItem("language")) {
        localStorage.setItem("language", "ru");
      }
      isTokenValid();
    })();
  }, []);

  if (!navigator.onLine) {
    return <OfflinePage />;
  }

  if (loading) {
    return <Preloader />;
  }

  if (user) {
    return <Layout />;
  }

  return <AuthorizationPage />;
};

export const AppWrap = () => {
  const classes = useStyles();
  const [currTheme, setCurrTheme] = useState<boolean>(true);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <ThemeProvider theme={currTheme ? lightTheme : darkTheme}>
          <CssBaseline />
          <ToastContainer
            position="top-center"
            className={classes.toastContainer}
            toastClassName={classes.toast}
            bodyClassName={classes.toastBody}
            limit={3}
            autoClose={3000}
            rtl={false}
            pauseOnFocusLoss
            draggable
            closeButton={false}
          />
          <App />
        </ThemeProvider>
      </Router>
    </MuiPickersUtilsProvider>
  );
};
