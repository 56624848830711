import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const MailIncomeOutlined = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H13V18H4V8L12 13L20 8V13H22V6C22 4.9 21.1 4 20 4ZM12 11L4 6H20L12 11Z" />
      <path d="M15 19L19 23V20H23V18H19V15L15 19Z" />
    </SvgIcon>
  );
};

export default MailIncomeOutlined;
