import { makeStyles, Theme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

export const useDialogProfileStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: "10px 16px",
    margin: "32px 16px",
  },
  paperFullWidth: {
    width: "calc(100% - 32px)",
  },
  title: {
    marginBottom: "20px",
    padding: 0,
    fontSize: "1rem",
    fontWeight: 500,
    color: blue[500],
  },
  content: {
    display: "flex",
    justifyContent: "center",
    padding: 0,
    "& svg": {
      display: "none !important",
    },
  },
  icon: {
    fontSize: "40px",
    color: blue[500],
  },
  innerContent: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "25px",
  },
  innerContentItem: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    "& a": {
      fontSize: "1rem",
      fontWeight: 500,
      color: theme.palette.grey[900],
      textDecoration: "none",
    },
    "& span": {
      fontSize: ".75rem",
      color: theme.palette.grey[700],
    },
  },
  actionsRoot: {
    display: "block",
    padding: "8px 0",
  },
  button: {
    textTransform: "none",
  },
  dialogActions: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gap: "16px",
    alignSelf: "center",
  },
  buttonClose: {
    color: blue[500],
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  buttonSend: {
    color: theme.palette.background.default,
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor: blue[700],
    },
  },
}));
