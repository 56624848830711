import { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";

const Portal: FC = ({ children }) => {
  const [container] = useState<HTMLDivElement>(() =>
    document.createElement("div"),
  );

  useEffect(() => {
    document.body.appendChild(container);
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.removeChild(container);
      document.body.style.overflowY = "auto";
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};

export default Portal;
