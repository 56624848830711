import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 104px)",
    minHeight: "calc(100vh - 104px)",
    padding: "16px",
    overflowY: "auto",
  },
  userPage: {
    height: "calc(100vh - 198px)",
    minHeight: "calc(100vh - 198px)",
  },
}));

interface SwipeableTabInnerContentProps {
  userPage?: boolean;
}

export const SwipeableTabInnerContent: FC<SwipeableTabInnerContentProps> = ({
  children,
  userPage = false,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${userPage ? classes.userPage : null}`}>
      {children}
    </div>
  );
};
