import { blue, green, orange, red } from "@material-ui/core/colors";
import { ServiceRequestEnum } from "models/common.enum";

export const tokenName: string = "Rent-Client";
export const url: string | undefined = process.env.REACT_APP_ERP_WEB_SERVER;

export const months: Array<string> = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

export const typeColorMap = {
  [ServiceRequestEnum.Electrician]: orange,
  [ServiceRequestEnum.Cleaning]: red,
  [ServiceRequestEnum.Plumbing]: blue,
  [ServiceRequestEnum.Another]: green,
};
