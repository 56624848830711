import create from "zustand";
import { INotificationsStore } from "stores/notifications/model/notifications-store.interface";
import { WSNotificationType } from "stores/notifications/model/notifications-store.enum";
import { NotificationsMappingObject } from "stores/notifications/model/notifications-store.type";

const matchObject: NotificationsMappingObject = {
  [WSNotificationType.Services]: "servicesHistory",
};

export const useNotificationsStore = create<INotificationsStore>((set) => ({
  alerts: false,
  appeal: false,
  houseHistory: false,
  metersHistory: false,
  servicesHistory: false,
  analysisHistory: false,
  setNotifications: (type) => {
    set((state) => {
      const newState = !state[matchObject[type]]
        ? {
            ...state,
            [matchObject[type]]: true,
          }
        : state;

      localStorage.setItem("NotificationsStore", JSON.stringify(newState));

      return newState;
    });
  },
  resetNotification: (type) => {
    set((state) => {
      const newState = { ...state, [type]: false };
      localStorage.setItem("NotificationsStore", JSON.stringify(newState));

      return { ...newState, persistStorage: newState };
    });
  },
  persistStorage: null,
  setPersistStorage: (storage) => {
    set((state) => {
      return { ...state, persistStorage: storage };
    });
  },
}));
