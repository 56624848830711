import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "5px",
    height: "64px",
  },
  itemTitle: {
    fontSize: "12px",
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  itemDesc: {
    color: theme.palette.grey[900],
  },
}));

interface UserInfoListItemProps {
  title: string;
  description?: string;
}

export const UserInfoListItem = ({
  title,
  description,
}: UserInfoListItemProps) => {
  const classes = useStyles();

  return (
    <div className={classes.listItem}>
      <span className={classes.itemTitle}>{title}</span>
      <span className={classes.itemDesc}>{description ?? "-"}</span>
    </div>
  );
};
