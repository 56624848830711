import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { Section } from "components/Section/Section";
import { UserInfoListItem } from "components/UserProfile/UserProfileTabs/components/UserInfoListItem";

import PersonOutlined from "assets/icons/PersonOutlined";
import { useExistUserStore } from "stores/auth/auth.store";

const useStyles = makeStyles(() => ({
  content: {
    display: "grid",
    gridTemplateColumns: "28px 1fr",
    gap: "16px",
  },
  iconWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "64px",
  },
  icon: {
    fontSize: "28px",
  },
}));

export const UserInfoTab: FC = () => {
  const classes = useStyles();
  const user = useExistUserStore();

  return (
    <>
      <Section title={"Жилец"}>
        <div className={classes.content}>
          <div className={classes.iconWrap}>
            <PersonOutlined className={classes.icon} />
          </div>
          <div>
            <UserInfoListItem title={"Фамилия"} description={user.lastName} />
            <UserInfoListItem title={"Имя"} description={user.firstName} />
            <UserInfoListItem
              title={"Отчетсво"}
              description={user.patronymic}
            />
            <UserInfoListItem
              title={"Дата рождения"}
              description={
                user.birthDate
                  ? moment(user.birthDate).format("DD.MM.YYYY")
                  : undefined
              }
            />
          </div>
        </div>
      </Section>
      <Section title={"Контакты"}>
        <div className={classes.content}>
          <div className={classes.iconWrap}>
            <PersonOutlined className={classes.icon} />
          </div>
          <div>
            <UserInfoListItem
              title={"Телефон"}
              description={user.mobileNumber}
            />
            <UserInfoListItem title={"Почта"} description={user.email} />
          </div>
        </div>
      </Section>
    </>
  );
};
