import { Route, Switch } from "react-router-dom";

import { UserProfile } from "components/UserProfile/UserProfile";
import { MainView } from "components/MainView/MainView";

export const Layout = () => (
  <Switch>
    <Route path={"/user-profile"} component={UserProfile} />
    <Route component={MainView} />
  </Switch>
);
