import { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { InputWrap } from "components/InputWrap/InputWrap";
import { useInputStyles } from "styles/useInputStyles";

import logo from "assets/img/logo.svg";

//auth
import { signIn } from "utils/auth.utils";
import { useAuthStore } from "stores/auth/auth.store";
import { AuthCredentials } from "models/auth/auth.interface";

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  paper: {
    marginTop: theme.spacing(8),
    width: "400px",
    maxWidth: "80vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const AuthorizationPage = () => {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const { setLoading, loading } = useAuthStore();
  const [credentials, setCredentials] = useState<AuthCredentials>({
    password: "",
    userName: "",
  });

  const changeCredentialsHandler = (
    currCredential: Partial<AuthCredentials>,
  ) => {
    setCredentials((prevState) => {
      return {
        ...prevState,
        ...currCredential,
      };
    });
  };

  const submitLogin = async (e: any) => {
    e.preventDefault();
    const { userName, password } = credentials;
    setLoading(true);

    await signIn({ userName, password });
    setLoading(false);
  };

  return (
    <>
      <Switch>
        <Route exact path={"/login"}>
          <Container
            component={"main"}
            maxWidth={"xs"}
            className={classes.containerRoot}
          >
            <div className={classes.paper}>
              <img
                className={classes.logo}
                src={logo}
                alt={"Жилой микрорайон"}
              />
              <Typography component={"h1"} variant={"h5"}>
                Авторизация
              </Typography>
              <form className={classes.form} onSubmit={submitLogin}>
                <>
                  <InputWrap>
                    <TextField
                      type={"text"}
                      variant={"filled"}
                      required
                      fullWidth
                      id="email"
                      label="Логин"
                      name="login"
                      autoComplete="login"
                      InputProps={{
                        classes: {
                          root: inputClasses.root,
                        },
                        disableUnderline: true,
                      }}
                      onChange={(e) =>
                        changeCredentialsHandler({ userName: e.target.value })
                      }
                    />
                  </InputWrap>
                  <InputWrap>
                    <TextField
                      variant="filled"
                      required
                      fullWidth
                      name="password"
                      label="Пароль"
                      type="password"
                      id="password"
                      InputProps={{
                        classes: {
                          root: inputClasses.root,
                        },
                        disableUnderline: true,
                      }}
                      onChange={(e) =>
                        changeCredentialsHandler({ password: e.target.value })
                      }
                    />
                  </InputWrap>
                </>

                <Button
                  type={"submit"}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {loading ? (
                    <>
                      <CircularProgress size={24} style={{ color: "#fff" }} />
                    </>
                  ) : (
                    "Вход"
                  )}
                </Button>
              </form>
            </div>
          </Container>
        </Route>
        <Redirect to={"/login"} />
      </Switch>
    </>
  );
};
