import { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import { ErrorType } from "components/ErrorBoundary/ErrorBoundary";

import { capitalizeFirstLetter } from "utils/common.utils";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "40px",
    height: "100vh",
    width: "100vw",
  },
  alertTitleRoot: {
    marginBottom: 0,
    fontSize: "1.5rem",
  },
  alertTitleIcon: {
    alignItems: "center",
  },
}));

interface ErrorInfoProps {
  error: NonNullable<ErrorType>;
  buttonText?: string;
  onButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const ErrorInfo: FC<ErrorInfoProps> = ({
  error,
  buttonText,
  onButtonClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const title =
    error instanceof Error
      ? capitalizeFirstLetter("что-то пошло не так")
      : error.result.status;
  const description = !(error instanceof Error)
    ? error.result.value
    : undefined;

  return (
    <div className={classes.root}>
      <Alert
        severity={"error"}
        variant={"outlined"}
        classes={{
          icon: classes.alertTitleIcon,
        }}
      >
        <AlertTitle
          classes={{
            root: classes.alertTitleRoot,
          }}
        >
          {title}
        </AlertTitle>
      </Alert>
      <Button
        variant={"contained"}
        color={"primary"}
        onClick={onButtonClick ?? (() => document.location.reload())}
      >
        Перезагрузить страницу
      </Button>
    </div>
  );
};

export default ErrorInfo;
