import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const EditFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z" />
    </SvgIcon>
  );
};

export default EditFilled;
