import { useHistory } from "react-router-dom";
import { Typography, IconButton, Avatar } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import AccountFilled from "assets/icons/AccountFilled";
import PointerRightFilled from "assets/icons/PointerRightFilled";

import { getImageFromBytes } from "utils/common.utils";
import { useExistUserStore } from "stores/auth/auth.store";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrap: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      color: theme.palette.grey[500],
    },
    avatar: {
      height: "40px",
      width: "40px",
    },
    icon: {
      fontSize: "40px",
    },
    content: {
      display: "flex",
      alignItems: "center",
      width: "55vw",
    },
    name: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: theme.palette.grey[900],
    },
    button: {
      padding: 0,
      color: theme.palette.grey[500],
    },
  }),
);

export const UserData = () => {
  const user = useExistUserStore();
  const classes = useStyles();
  const history = useHistory();

  const goToUserProfile = () => {
    history.push("/user-profile");
  };

  return (
    <div className={classes.wrap}>
      {user.profileImage ? (
        <Avatar
          className={classes.avatar}
          alt="ava"
          src={getImageFromBytes(user.profileImage)}
        />
      ) : (
        <AccountFilled className={classes.icon} />
      )}
      <div>
        <div className={classes.content}>
          <Typography className={classes.name}>
            {user?.lastName} {user.firstName}
          </Typography>
          <IconButton className={classes.button} onClick={goToUserProfile}>
            <PointerRightFilled />
          </IconButton>
        </div>
        <Typography>Мои данные</Typography>
      </div>
    </div>
  );
};
