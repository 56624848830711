import { useState } from "react";
import SwipeableViews from "react-swipeable-views";

import { makeStyles } from "@material-ui/core/styles";

import { AppBarWithTabs } from "components/AppBarWithTabs/AppBarWithTabs";
import { SwipeableTabInnerContent } from "../../SwipeableTabs/SwipeableTabInnerContent/SwipeableTabInnerContent";
import { UserInfoTab } from "components/UserProfile/UserProfileTabs/UserInfoTab";
import { UserSettingsTab } from "../UserProfileTabs/UserSettingsTab";

const useStyles = makeStyles(() => ({
  root: {
    padding: "150px 0 0",
  },
}));

export const UserProfileSwipeable = () => {
  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const handleTabChange = (index: number) => {
    setIndex(index);
  };

  return (
    <div className={classes.root}>
      <AppBarWithTabs
        index={index}
        handleChange={(_e, index) => handleTabChange(index)}
        tabs={[{ name: "Мои данные" }, { name: "Настройки" }]}
        user
      />
      <SwipeableViews index={index} onChangeIndex={handleTabChange}>
        <SwipeableTabInnerContent userPage>
          <UserInfoTab />
        </SwipeableTabInnerContent>
        <SwipeableTabInnerContent userPage>
          <UserSettingsTab />
        </SwipeableTabInnerContent>
      </SwipeableViews>
    </div>
  );
};
