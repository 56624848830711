import { createTheme } from "@material-ui/core/styles";

export const lightTheme = createTheme({
  palette: {
    text: {
      primary: "#666666",
      secondary: "#9e9e9e",
    },
    background: {
      default: "#fff",
    },
    grey: {
      50: "#fafafa", //Black 2%
      100: "#f5f5f5", //Black 4%
      200: "#ebebeb", //Black 8%
      300: "#e0e0e0", //Black 12%
      400: "#cccccc", //Black 20%
      500: "#9e9e9e", //Black 38%
      600: "#757575",
      700: "#666666", //Black 60%
      800: "#424242",
      900: "#212121", //Black 87%
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
  },
  spacing: 6,
});

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#121212",
    },
  },
});
