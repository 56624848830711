import { handleErrors } from "utils/request.utils";
import { HttpService } from "../HttpService";
import { AuthCredentials, AuthToken } from "models/auth/auth.interface";
import { DefaultResponse } from "models/http-request.interface";
import i18n from "i18next";
import { invalidCredentials } from "i18n/constants/errors.translation";
import { capitalizeFirstLetter } from "utils/common.utils";

const AccountService = new HttpService("/account");

export const getAuthToken = (
  body: AuthCredentials,
): Promise<DefaultResponse<AuthToken>> => {
  return AccountService.post("/token", body)
    .then(async (res) => {
      if (res.ok) return res.json();

      if (res.status === 403) {
        return Promise.reject({
          isSuccess: false,
          result: {
            status: res.status,
            value: capitalizeFirstLetter(i18n.t(invalidCredentials)),
          },
        });
      }

      return handleErrors(res);
    })
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

export const validateAuthToken = () => {
  return AccountService.post("/token-valid")
    .then(handleErrors)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};
