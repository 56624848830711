import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import shallow from "zustand/shallow";

import { AppBarWithTabs } from "components/AppBarWithTabs/AppBarWithTabs";
import { SwipeableTabInnerContent } from "../SwipeableTabs/SwipeableTabInnerContent/SwipeableTabInnerContent";
import { ServicesMainTab } from "./ServicesTabs/ServicesMainTab";
import { ServicesHistoryTab } from "./ServicesTabs/ServicesHistoryTab";
import { useNotificationsStore } from "stores/notifications/notifications.store";

export const Services = () => {
  const { servicesHistory, persistStorage } = useNotificationsStore(
    (state) => ({
      servicesHistory: state.servicesHistory,
      persistStorage: state.persistStorage,
    }),
    shallow,
  );

  const [index, setIndex] = useState(0);

  const handleTabChange = (index: number) => {
    setIndex(index);
  };

  return (
    <>
      <AppBarWithTabs
        index={index}
        handleChange={(e, index) => handleTabChange(index)}
        tabs={[
          { name: "Услуги" },
          {
            name: "История",
            showNotificationIcon:
              servicesHistory || persistStorage?.servicesHistory,
          },
        ]}
      />
      <SwipeableViews
        index={index}
        onChangeIndex={handleTabChange}
        containerStyle={{
          transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
        }}
      >
        <SwipeableTabInnerContent>
          <ServicesMainTab />
        </SwipeableTabInnerContent>
        <SwipeableTabInnerContent>
          <ServicesHistoryTab index={index} />
        </SwipeableTabInnerContent>
      </SwipeableViews>
    </>
  );
};
